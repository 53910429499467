import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Paper, IconButton, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Polyline, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix Leaflet default icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Function to update map view when position changes
function MapUpdater({ position }) {
    const map = useMap();
    useEffect(() => {
        if (position) {
            map.setView([position.lat, position.lng]);
        }
    }, [position, map]);
    return null;
}

function StepTracking() {
    const navigate = useNavigate();
    const [isTracking, setIsTracking] = useState(false);
    const isTrackingRef = useRef(false);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [pathCoordinates, setPathCoordinates] = useState([]);
    const [totalDistance, setTotalDistance] = useState(0);
    const [timer, setTimer] = useState(0);
    const [speed, setSpeed] = useState(0);
    const [watchId, setWatchId] = useState(null);
    const initializedRef = useRef(false);
    const lastUpdateRef = useRef(null);
    const [walkPath, setWalkPath] = useState([]);
    const [locationError, setLocationError] = useState(null);
    const [permissionStatus, setPermissionStatus] = useState(null);

    // Calculate distance between two points using Haversine formula
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Earth's radius in km
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
            Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return R * c; // Distance in km
    };

    // Get initial position when component mounts
    useEffect(() => {
        if (initializedRef.current) return;
        initializedRef.current = true;
        
        if ("geolocation" in navigator) {
            // Request permission explicitly
            navigator.permissions.query({ name: 'geolocation' }).then(result => {
                setPermissionStatus(result.state);
                console.log('Geolocation permission:', result.state);
                
                if (result.state === 'granted') {
                    requestLocation();
                } else {
                    setLocationError('Please enable location services for this app.');
                }
            });
        } else {
            setLocationError('Geolocation is not supported by your browser');
        }
    }, []);

    // Add this function to handle location requests
    const requestLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log('Got position:', position.coords);
                const { latitude, longitude } = position.coords;
                setCurrentPosition({ lat: latitude, lng: longitude });
                setLocationError(null);
                setPermissionStatus('granted');
            },
            (error) => {
                console.error('Location error:', error);
                let errorMsg = 'Unable to get your location.';
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        errorMsg = 'Please allow location access in your device settings.';
                        break;
                    case error.POSITION_UNAVAILABLE:
                        errorMsg = 'Location information is unavailable.';
                        break;
                    case error.TIMEOUT:
                        errorMsg = 'Location request timed out.';
                        break;
                    default:
                        errorMsg = 'An unknown error occurred.';
                }
                setLocationError(errorMsg);
            },
            {
                enableHighAccuracy: true,  // Use GPS for higher accuracy
                timeout: 10000,            // Wait up to 10 seconds
                maximumAge: 0              // Don't use cached position
            }
        );
    };

    // Monitor isTracking changes
    useEffect(() => {
        if (isTracking) {
            console.log('Tracking started:', isTracking);
        }
    }, [isTracking]);

    // Handle position updates
    const handlePositionUpdate = (position) => {
        const { latitude, longitude } = position.coords;
        console.log('GPS Update:', { lat: latitude.toFixed(6), lng: longitude.toFixed(6) });

        const newPosition = { lat: latitude, lng: longitude };
        setCurrentPosition(newPosition);
        
        if (isTracking) {
            // Add point to walk path
            setWalkPath(prev => {
                const newPoint = [latitude, longitude];
                if (prev.length > 0) {
                    const lastPos = prev[prev.length - 1];
                    const distance = calculateDistance(
                        lastPos[0], lastPos[1],
                        latitude, longitude
                    );
                    
                    if (distance > 0.002) {  // Only add if moved more than 2m
                        console.log('Adding point to path:', newPoint);
                        return [...prev, newPoint];
                    }
                }
                return prev.length === 0 ? [newPoint] : prev;
            });

            setPathCoordinates(prev => {
                if (prev.length > 0) {
                    const lastPos = prev[prev.length - 1];
                    const distance = calculateDistance(
                        lastPos[0], lastPos[1],
                        latitude, longitude
                    );
                    
                    // Add timestamp check to prevent double updates
                    const now = Date.now();
                    if (!lastUpdateRef.current || now - lastUpdateRef.current > 1000) {
                        lastUpdateRef.current = now;
                        
                        if (distance > 0.002) {
                            console.log('Updating distance:', distance * 1000, 'meters');
                            setTotalDistance(prevDist => prevDist + (distance * 1000));
                            setSpeed((distance * 1000) / (1/3600));
                            return [...prev, [latitude, longitude]];
                        }
                    }
                    return prev;
                }
                return [[latitude, longitude]];
            });
        }
    };

    // Start tracking
    const startTracking = () => {
        console.log('Starting tracking...');
        setLocationError(null);
        console.log('Permission status:', permissionStatus);
        if (permissionStatus !== 'granted') {
            requestLocation(); // This will trigger the permission prompt
            return;
        }

        lastUpdateRef.current = null;
        setIsTracking(true);
        setTimer(0);
        setTotalDistance(0);
        setWalkPath([]);
        
        if ("geolocation" in navigator) {
            const id = navigator.geolocation.watchPosition(
                handlePositionUpdate,
                (error) => {
                    console.error("Tracking error:", error);
                    setLocationError('Lost GPS signal. Please check your location settings.');
                    stopTracking();
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                }
            );
            setWatchId(id);
        }
    };

    // Stop tracking
    const stopTracking = () => {
        console.log('Stopping tracking...');
        setIsTracking(false);
        if (watchId) {
            console.log('Clearing watch with ID:', watchId);
            navigator.geolocation.clearWatch(watchId);
            setWatchId(null);
        }
    };

    // Timer effect
    useEffect(() => {
        let interval;
        if (isTracking) {
            interval = setInterval(() => {
                setTimer(prev => prev + 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isTracking]);

    // Format time for display (MM:SS)
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <Box sx={{ bgcolor: '#f8f9fa', minHeight: '100vh' }}>
            {/* Header */}
            <Paper elevation={0} sx={{ p: 2, bgcolor: '#1e3a8a', color: 'white' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton onClick={() => navigate('/home')} sx={{ color: 'white' }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">Step Tracking</Typography>
                </Box>
            </Paper>

            {/* Map Container */}
            <Box sx={{ height: '60vh', position: 'relative' }}>
                <MapContainer
                    center={currentPosition ? [currentPosition.lat, currentPosition.lng] : [22.302711, 114.177216]}
                    zoom={15}
                    style={{ height: '100%', width: '100%' }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; OpenStreetMap contributors'
                    />
                    {currentPosition && (
                        <>
                            <Marker position={[currentPosition.lat, currentPosition.lng]} />
                            <MapUpdater position={currentPosition} />
                        </>
                    )}
                    {walkPath.length > 1 && (
                        <Polyline
                            positions={walkPath}
                            pathOptions={{
                                color: '#1e3a8a',
                                weight: 4,
                                opacity: 0.8,
                                lineCap: 'round',
                                lineJoin: 'round'
                            }}
                        />
                    )}
                </MapContainer>

                {/* GPS Debug Info */}
                <Box sx={{
                    position: 'absolute',
                    top: 80,  // Below the timer
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bgcolor: 'rgba(255,255,255,0.9)',
                    p: 2,
                    borderRadius: 2,
                    textAlign: 'left',
                    zIndex: 1000,
                    minWidth: '200px'
                }}>
                    <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                        GPS Coordinates:<br />
                        Lat: {currentPosition?.lat?.toFixed(6) || 'N/A'}<br />
                        Lng: {currentPosition?.lng?.toFixed(6) || 'N/A'}<br />
                        Speed: {speed.toFixed(2)} m/h<br />
                        Distance: {totalDistance.toFixed(2)}m<br />
                        Points: {pathCoordinates.length}
                    </Typography>
                </Box>

                {/* Timer and Distance Overlay */}
                <Box sx={{
                    position: 'absolute',
                    top: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bgcolor: 'rgba(255,255,255,0.9)',
                    p: 2,
                    borderRadius: 2,
                    textAlign: 'center',
                    zIndex: 1000
                }}>
                    <Typography variant="h4">{formatTime(timer)}</Typography>
                    <Typography variant="body2">
                        {totalDistance.toFixed(0)} meters
                    </Typography>
                </Box>

                {/* Start/Stop Button */}
                <Button
                    variant="contained"
                    onClick={() => {
                        console.log('Button clicked');
                        if (isTracking) {
                            stopTracking();
                        } else {
                            startTracking();
                        }
                    }}
                    sx={{
                        position: 'absolute',
                        bottom: 20,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        borderRadius: '50%',
                        width: 64,
                        height: 64,
                        minWidth: 64,
                        bgcolor: isTracking ? '#dc2626' : '#1e3a8a',
                        zIndex: 1000,
                        '&:hover': {
                            bgcolor: isTracking ? '#b91c1c' : '#1e40af'
                        }
                    }}
                >
                    {isTracking ? <PauseIcon /> : <PlayArrowIcon />}
                </Button>
            </Box>

            {locationError && (
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'rgba(255,255,255,0.9)',
                    p: 2,
                    borderRadius: 2,
                    textAlign: 'center',
                    zIndex: 1000,
                    maxWidth: '80%'
                }}>
                    <Typography color="error">
                        {locationError}
                    </Typography>
                    {permissionStatus !== 'granted' && (
                        <Button
                            variant="contained"
                            onClick={requestLocation}
                            sx={{ mt: 2 }}
                        >
                            Enable Location
                        </Button>
                    )}
                </Box>
            )}
        </Box>
    );
}

export default StepTracking; 
