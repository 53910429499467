import './AIChatbox.css';

import React, { useState, useEffect, useRef } from 'react';
import { MuiFileInput } from 'mui-file-input';
import Loading from './Loading';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { fetchUserAttributes } from '@aws-amplify/auth';

import { usePetContext } from '../context/PetContext';
import defaultPetImage from '../assets/paw-print.png';
import botAvatar from '../assets/bot-avatar.png';
import TypingAnimation from './TypingAnimation';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from '../translations';

function AIChatbox() {
    const { currentLang } = usePetContext();
    const t = useTranslations(currentLang);
    
    const { userPetInfo } = usePetContext();
    console.log('userPetInfo', userPetInfo);
    const petImage = userPetInfo?.File_name ? `https://petalife-pet.s3.us-east-2.amazonaws.com/${userPetInfo.File_name}` : defaultPetImage;
    // State to store the data
    const [data, setData] = useState(null);
    // State to manage loading state
    const [isLoading, setIsLoading] = useState(true);
    // State to manage errors
    const [error, setError] = useState(null);

    // Detect whether the current device is a mobile device
    const isMobile = useMediaQuery('(max-width:600px)');

    // Get the user's attributes
    const [userAttributes, setUserAttributes] = useState(null);

    const navigate = useNavigate();

    // Welcome Message
    const welcomeMessage = (language) => {
        if (language === 'zh') {
            return (
                <>
                    你好，我是由Petalife團隊研發的人工智慧獸醫助理。
                    <br />
                    我的工作是協助寵物預先診斷，以便及早發現和治療。
                    <br />
                    你可上傳寵物糞便的照片以檢查其健康狀況或；
                    <br />
                    問我任何寵物健康相關的問題，例如：「我應該給我的寵物吃什麼令他的腸道更健康？」以獲取即時的建議。
                </>
            );
        } else {
            return (
                <>
                    Hello, I am an AI veterinary assistant developed by the Petalife team.
                    <br />
                    My job is to assist in the early diagnosis of pets, so that problems can be detected and treated early.
                    <br />
                    You can upload a photo of your pet's stool to check its health status, or;
                    <br />
                    Ask me any pet health - related questions, such as "What should I feed my pet to make their digestive system healthier?" to get immediate advice.
                </>
            );
        }
    };

    // Add welcome message when the component is first rendered
    const didAddWelcomeMessage = useRef(false);
    // Add welcome message when the language is switched
    //const [prevLanguage, setPrevLanguage] = useState('zh');

    // Execute when the component is first rendered
    useEffect(() => {

        // Asynchronously fetch data from an API
        const fetchData = async () => {
            try {
                const response = await fetch('https://4d2kgb9xrh.execute-api.us-east-2.amazonaws.com/UAT', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: '',
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setData(data);

                // Get the user's attributes
                const userAttributes = await fetchUserAttributes();
                setUserAttributes(userAttributes);

            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    // State to store the message during the chat
    const [messages, setMessages] = useState([]);
    // State to store the user input in the chatbox
    const [userInput, setUserInput] = useState('');
    // State to indicate whether the chatbot is typing a message
    const [botTyping, setBotTyping] = useState(false);

    // Logic for processing messages and/or images sent by users
    const handleSend = async () => {

        // setBotTyping(true)
        const thread_id = JSON.parse(data.body)['thread_id']
        // console.log(thread_id)

        // If there is neither text case nor image case, return directly
        if (!userInput.trim()) return;

        // If only text case
        else {

            // Indicate the chatbot is typing a message
            setBotTyping(true)

            const messagePayload = {
                thread_id: thread_id,

                // Set the language of the chatbot answers according to the application language
                user_text_input: currentLang === '繁' ? userInput.trim() + '(用繁體中文答)' : userInput.trim() + '(Answer in English)',
                user_name: userName,
                user_id: userAttributes.sub,
                user_email: userAttributes.email,
            };

            // Add user message to the state of messages
            addMessage('user', userInput, '');

            setUserInput('');

            // Send user message to the backend
            await openaiAddMessageAPI(messagePayload)

            // Get chatbot response message
            const runThreadLoad = {
                thread_id: thread_id,
            };
            const botResponse = await openaiRunThreadAPI(runThreadLoad)

            // Iterate through chatbot responses and add them to the state of messages
            for (let i = botResponse.length; i > 0; i--) {
                const formattedText = botResponse[i - 1].replace(/\n/g, '<br>');
                const botMessage = <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
                addMessage('bot', botMessage); // Display bot response
            }
            // Indicate the chatbot finishes typing a message
            setBotTyping(false)
        }

    };

    // Add user message and/or image to the state of messages
    const addMessage = (sender, text, image = '') => {
        setMessages((prevMessages) => [...prevMessages, { sender, text, image }]);
    };

    // Interact with OpenAI's API and obtain response
    const openaiAddMessageAPI = async (messagePayload) => {
        const response = await fetch('https://s8gv18zkt5.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload)
        });
        // console.log(messagePayload)
        //Return data
        const data = await response.json();
        return data || 'Sorry, I could not process that.';
    };

    const openaiAddMessageWithFileAPI = async (messagePayload) => {
        const response = await fetch('https://fcisclxd1l.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload)
        });

        // Extract msg_content from data and return it
        const data = await response.json();
        const dataJson = JSON.parse(data.body)
        const msgList = dataJson['msg_content']
        // console.log("dataaaaaaaaaa:", msgList)
        return msgList || 'Sorry, I could not process that.';
    };

    const openaiRunThreadAPI = async (messagePayload) => {
        const response = await fetch('https://ecr6o1jfo5.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload),
        });

        // Extract msg_list from data and return it
        const data = await response.json();
        const dataJson = JSON.parse(data.body)
        const msgList = dataJson['msg_list']
        // console.log("dataaaa:", msgList)
        return msgList || 'Sorry, I could not process that.';
    };
    // Convert the selected file into a base64 string
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Scroll to bottom when messages update
    useEffect(() => {
        scrollToBottom();
    }, [messages, botTyping]);

    // If isLoading, render Loading component
    if (isLoading) {
        return (
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#FFA726'
                }}
            >
                <CircularProgress
                    sx={{
                        color: 'white',
                        mb: 2
                    }}
                />
                <Typography
                    sx={{
                        color: 'white',
                        fontWeight: 500
                    }}
                >
                    Loading...
                </Typography>
            </Box>
        );
    }

    // Get User Name
    let userName = userAttributes.sub;

    if (userAttributes.identities) {
        const userAttributesIdentities = JSON.parse(userAttributes.identities);
        userName = `google_${userAttributesIdentities[0].userId}`;
    }

    return (
        <Box sx={{ flexGrow: 1, bgcolor: '#f8f9fa', minHeight: '100vh' }}>
            {/* Header */}
            <Box
                sx={{
                    bgcolor: '#1e3a8a',
                    color: 'white',
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    borderRadius: '0 0 25px 25px',
                    height: '80px'
                }}
            >
                <IconButton
                    onClick={() => navigate('/home')}
                    sx={{
                        color: 'white',
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '12px'
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {t.aiChat.title}
                </Typography>
            </Box>

            {/* Chat messages container */}
            <Box sx={{ p: 2, mb: 8, maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                {/* Welcome Message */}
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 3, alignItems: 'flex-start' }}>
                    <div style={{
                        width: 40,
                        height: 40,
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img
                            src={botAvatar}
                            alt="Bot"
                            style={{ width: 24, height: 24 }}
                        />
                    </div>
                    <Box sx={{
                        maxWidth: '75%',
                        bgcolor: '#f3f4f6',
                        p: 2,
                        borderRadius: '12px',
                        boxShadow: 'none'
                    }}>
                        <Typography>
                            {t.aiChat.welcomeMessage}
                        </Typography>
                    </Box>
                </Box>

                {/* Chat Messages */}
                {messages.map((msg, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: msg.sender === 'user' ? 'row-reverse' : 'row',
                            gap: 2,
                            mb: 3,
                            alignItems: 'flex-start'
                        }}
                    >
                        {msg.sender === 'user' ? (
                            <img
                                src={petImage}
                                alt="Pet"
                                style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: '12px',
                                    objectFit: 'cover'
                                }}
                            />
                        ) : (
                            <div style={{
                                width: 40,
                                height: 40,
                                backgroundColor: 'white',
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <img
                                    src={botAvatar}
                                    alt="Bot"
                                    style={{ width: 24, height: 24 }}
                                />
                            </div>
                        )}
                        <Box sx={{
                            maxWidth: '75%',
                            bgcolor: msg.sender === 'bot' ? '#f3f4f6' : 'white',
                            p: 2,
                            borderRadius: '12px',
                            boxShadow: msg.sender === 'user' ? '0 1px 3px rgba(0,0,0,0.1)' : 'none'
                        }}>
                            <Typography>
                                {msg.text}
                            </Typography>
                        </Box>
                    </Box>
                ))}

                {/* Bot Typing Indicator */}
                {botTyping && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 3, alignItems: 'flex-start' }}>
                        <div style={{
                            width: 40,
                            height: 40,
                            backgroundColor: 'white',
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img
                                src={botAvatar}
                                alt="Bot"
                                style={{ width: 24, height: 24 }}
                            />
                        </div>
                        <Box sx={{
                            maxWidth: '75%',
                            bgcolor: '#f3f4f6',
                            p: 2,
                            borderRadius: '12px',
                            boxShadow: 'none'
                        }}>
                            <TypingAnimation />
                        </Box>
                    </Box>
                )}
            </Box>

            {/* Input Area */}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    p: 2,
                    bgcolor: 'white',
                    borderTop: '1px solid #e5e7eb'
                }}
            >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder={t.aiChat.chatPlaceholder}
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSend();
                            }
                        }}
                        multiline
                        maxRows={4}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                            }
                        }}
                    />
                    <IconButton
                        onClick={handleSend}
                        disabled={!userInput.trim()}
                        sx={{
                            bgcolor: '#1e3a8a',
                            color: 'white',
                            '&:hover': {
                                bgcolor: '#1e40af'
                            },
                            '&.Mui-disabled': {
                                bgcolor: '#e5e7eb',
                                color: '#9ca3af'
                            }
                        }}
                    >
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default AIChatbox;
