import './App.css';

import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import PetSelection from './components/PetSelection';
// import CreatePet from './components/CreatePet';
import AIChatbox from './components/AIChatbox';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import LandingPage from './components/LandingPage';
import HomePage from './components/HomePage';
import Bar from './components/Bar';
import CreatePet from './components/CreatePet';
import StoolAnalysis from './components/StoolAnalysis';
import StoolAnalysisRequest from './components/StoolAnalysisRequest';
import StoolAnalysisReport from './components/StoolAnalysisReport';
import { useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, Box } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import petsALife from './assets/petalife_logo.png';
import Button from '@mui/material/Button';

import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider, Theme, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import ScorePanel from './components/ScorePanel';
import { PetProvider } from './context/PetContext';
import StepTracking from './components/StepTracking';
import dogIcon from './assets/homepage_picture.png';

Amplify.configure(awsExports);

// Application main entry point
function App() {
    return (
        <BrowserRouter>
            <MainApp />
        </BrowserRouter>
    );

}

// Add new SplashScreen component
function SplashScreen({ onStart }) {
    return (
        <Box
            sx={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#FAE5D3', // Peachy background color
                padding: 2
            }}
        >
            <Box
                component="img"
                src={petsALife}
                alt="Petalife Logo"
                sx={{
                    width: { xs: '300px', sm: '350px' }, // Increased logo size
                    height: 'auto',
                    marginBottom: 3
                }}
            />
            <Typography
                variant="h5" // Increased from subtitle1 to h5
                sx={{
                    color: '#666',
                    marginBottom: 2,
                    textAlign: 'center',
                    fontSize: { xs: '1.3rem', sm: '1.5rem' } // Bigger font size
                }}
            >
                AI-driven Pet Care Platform
            </Typography>
            <Box
                component="img"
                src={dogIcon}
                alt="Cute Dog"
                sx={{
                    width: { xs: '250px', sm: '300px' }, // Increased dog icon size
                    height: 'auto',
                    marginBottom: 5
                }}
            />
            <Button
                onClick={onStart}
                variant="contained"
                sx={{
                    bgcolor: '#FF953B',
                    color: 'white',
                    marginTop: 3,
                    paddingX: 6, // Increased padding
                    paddingY: 1.5, // Increased padding
                    borderRadius: '25px', // Increased border radius
                    fontSize: '1.2rem', // Bigger font size
                    '&:hover': {
                        bgcolor: '#e67e22'
                    }
                }}
            >
                START
            </Button>
        </Box>
    );
}

function MainApp() {
    // Use sessionStorage instead of localStorage
    const [showSplash, setShowSplash] = useState(() => {
        // Check if we've shown the splash screen in this session
        const hasShownSplash = sessionStorage.getItem('hasShownSplash');
        return !hasShownSplash;
    });

    // Update both state and sessionStorage
    const handleSplashStart = () => {
        sessionStorage.setItem('hasShownSplash', 'true');
        setShowSplash(false);
    };

    // Detect whether the current device is a mobile device
    const isMobile = useMediaQuery('(max-width:600px)');

    // Create a state variable, set an initial value, and create a function that updates the state
    const [language, setLanguage] = React.useState('zh');

    // Function, used to switch the value of language status
    const toggleLanguage = () => {
        setLanguage(language === 'zh' ? 'en' : 'zh');
    };

    const { tokens } = useTheme();
    // Extend the default theme
    const theme: Theme = {
        name: 'Auth Example Theme',
        tokens: {
            colors: {
                neutral: {
                    '80': '#FAE5D3',  // Setting your custom color for neutral['80']
                },
            },
            components: {
                authenticator: {
                    router: {
                        boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
                        borderWidth: '0',
                    },
                    form: {
                        padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
                    },
                },
                button: {
                    primary: {
                        backgroundColor: '#FF953B',
                    },
                    link: {
                        color: tokens.colors.neutral['100'],
                    },
                },
                fieldcontrol: {
                    _focus: {
                        boxShadow: `0 0 0 2px ${tokens.colors.neutral['100']}`,
                    },
                },
                tabs: {
                    item: {
                        color: tokens.colors.neutral['80'],
                        _active: {
                            borderColor: '#FAE5D3',
                            color: tokens.colors.neutral['100'],
                        },
                    },
                },
            },
        },
    };


    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const components = {
        Header() {
            const { tokens } = useTheme();
    
            return (
                <Box sx={{
                    width: '100%',
                    // position: 'relative',
                    // left: '50%',
                    // right: '50%',
                    // marginLeft: '-50vw',
                    // marginRight: '-50vw',
                    // marginBottom: 3
                }}>
                    <AppBar
                        // position="static"
                        elevation={0}
                        sx={{
                            background: '#FAE5D3',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            width: '100vw'
                        }}
                    >
                        <Toolbar
                            sx={{
                                padding: isMobile ? '12px 16px' : '16px 24px',
                                minHeight: 'auto',
                                width: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <img
                                    src={petsALife}
                                    alt="PetsALife Logo"
                                    style={{
                                        width: isMobile ? '120px' : '180px',
                                        height: 'auto',
                                        transition: 'width 0.3s ease'
                                    }}
                                />
                                {/* <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: '#666',
                                        marginTop: '4px',
                                        fontSize: isMobile ? '0.75rem' : '0.875rem'
                                    }}
                                >
                                    From tech to tail, better life for your pets
                                </Typography> */}
                            </Box>
                        </Toolbar>
                    </AppBar>
    
                    <Box
                        sx={{
                            // display: 'flex',
                            // flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '400px',
                            margin: '0 auto',
                            padding: isMobile ? '24px 16px' : '40px 24px',
                            '& .amplify-tabs': {
                                marginTop: '24px'
                            }
                        }}
                    >
                        <Typography
                            variant={isMobile ? "h5" : "h4"}
                            component="h1"
                            sx={{
                                mb: 2,
                                fontWeight: 500,
                                color: '#666',
                                textAlign: 'center'
                            }}
                        >
                            <br/>
                            
                            {/* Login */}
                        </Typography>
    
                        <Typography
                            variant={isMobile ? "body2" : "body1"}
                            component="p"
                            sx={{
                                mb: 1,
                                color: '#666',
                                textAlign: 'center'
                            }}
                        >
                            By signing in you are agreeing to our
                        </Typography>
    
                        <Typography
                            onClick={handleClickOpen}
                            sx={{
                                color: '#E67E22',
                                cursor: 'pointer',
                                fontSize: isMobile ? '0.875rem' : '1rem',
                                fontWeight: 500,
                                textAlign: 'center',
                                '&:hover': {
                                    textDecoration: 'underline'
                                },
                                transition: 'color 0.2s ease'
                            }}
                        >
                            Terms and Privacy Policy
                        </Typography>
                    </Box>
                </Box>
            );
        },
    };

    if (showSplash) {
        return <SplashScreen onStart={handleSplashStart} />;
    }

    return (
        <div>
            <PetProvider>
                <ThemeProvider theme={theme}>
                    <Authenticator socialProviders={['google']} components={components}>
                        <Routes>
                            <Route path="/" element={<LandingPage toggleLanguage={toggleLanguage} language={language} />} />
                            <Route path="createpet" element={<CreatePet toggleLanguage={toggleLanguage} language={language} />} />
                            <Route path="home" element={<HomePage toggleLanguage={toggleLanguage} language={language} />} />
                            <Route path="about" element={<AboutUs toggleLanguage={toggleLanguage} language={language} />} />
                            <Route path="contact" element={<ContactUs toggleLanguage={toggleLanguage} language={language} />} />
                            <Route path="aichatbox" element={<AIChatbox toggleLanguage={toggleLanguage} language={language} />} />
                            <Route path="score-panel" element={<ScorePanel />} />
                            <Route path="stool-analysis" element={<StoolAnalysis />} />
                            <Route path="stool-analysis-request" element={<StoolAnalysisRequest />} />
                            <Route path="stool-analysis-report" element={<StoolAnalysisReport />} />
                            <Route path="step-tracking" element={<StepTracking />} />
                        </Routes>
                    </Authenticator>
                </ThemeProvider>
            </PetProvider>
            <Dialog open={open} onClose={handleClose} fullScreen={isMobile} maxWidth="md" PaperProps={{ style: { margin: isMobile ? '0' : '32px', maxHeight: isMobile ? '100%' : '90vh', padding: isMobile ? '16px' : '24px' } }}>
                <DialogTitle style={{ fontSize: isMobile ? '1.2rem' : '1.5rem', padding: isMobile ? '12px 16px' : '16px 24px' }}>Terms and Conditions & Privacy Policy</DialogTitle>
                <DialogContent style={{ padding: isMobile ? '16px' : '24px' }}>
                    <DialogContentText style={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
                        <Typography variant="body1" paragraph>
                            Last Updated: 22 September, 2024
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Welcome to Petalife! These Terms and Conditions and Privacy Policy ("Agreement") govern your access to and use of our web application ("Service") provided by Petalife ("we," "us," or "our"). By accessing or using our Service, you agree to be bound by this Agreement. If you do not agree with any part of this Agreement, please do not use our Service.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            1. Introduction
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Petalife provides an AI-driven tool designed to help pet owners monitor their pets' health by classifying stool types based on the Bristol Stool Scale (BSS) for dogs and cats. This Agreement outlines the terms under which you may use our Service and explains how we collect, use, disclose, and safeguard your information.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            2. Use of the Service
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Purpose: Our Service assists in understanding your pet's digestive health but is not a substitute for professional veterinary advice.</li>
                                <li>Compliance: You agree to use the Service only for lawful purposes and in accordance with this Agreement.</li>
                            </ul>
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            3. User Responsibilities
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Accurate Information: Provide accurate and complete information when using the Service.</li>
                                <li>Account Security: Maintain the confidentiality of your account credentials and are responsible for all activities under your account.</li>
                                <li>Compliance: Adhere to all applicable laws and regulations in using the Service.</li>
                            </ul>
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            4. Intellectual Property
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Ownership: All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are owned by Petalife or its licensors and are protected by intellectual property laws.</li>
                                <li>Limited License: We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Service for personal, non-commercial purposes.</li>
                            </ul>
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            5. Data Collection and Privacy
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            5.1. Information We Collect
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Personal Information:
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Email Address: To create and manage your account, send updates, and communicate with you.</li>
                                <li>Basic User Data: Such as your name, contact information, and other necessary details to provide and improve our Service.</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Pet Information:
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Pet Image Data: Images of your pet's stool, used internally for analysis and shared with our corporate academic research partners to enhance our AI models and contribute to scientific research.</li>
                            </ul>
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            5.2. How We Use Your Information
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Personal Information:
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Account Management: To create and maintain your account.</li>
                                <li>Communication: To send you updates, newsletters, and respond to your inquiries.</li>
                                <li>Service Improvement: To analyze usage patterns and improve our Service.</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Pet Image Data:
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Internal Analysis: To develop and refine our AI models for better accuracy in stool classification.</li>
                                <li>Academic Research: Shared with our corporate academic research partners under strict confidentiality agreements to support scientific studies and advancements in pet health monitoring.</li>
                            </ul>
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            5.3. Data Sharing and Disclosure
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Third-Party Partners: We do not sell your personal data. Your data is shared only with our academic research partners who are bound by confidentiality agreements.</li>
                                <li>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                            </ul>
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            5.4. Data Security
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We implement a variety of security measures to maintain the safety of your personal information:
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Encryption: Data transmission is secured through SSL encryption.</li>
                                <li>Access Controls: Access to personal data is restricted to authorized personnel only.</li>
                                <li>Regular Audits: We conduct regular security assessments to identify and mitigate potential vulnerabilities.</li>
                            </ul>
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            5.5. Your Rights
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Depending on your jurisdiction, you may have the following rights regarding your personal data:
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>Access: Request access to your personal data.</li>
                                <li>Correction: Request correction of inaccurate or incomplete data.</li>
                                <li>Deletion: Request deletion of your personal data.</li>
                                <li>Restriction: Request restriction of processing your data.</li>
                                <li>Portability: Request transfer of your data to another service provider.</li>
                                <li>Objection: Object to the processing of your data for specific purposes.</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" paragraph>
                            To exercise these rights, please contact us using the information provided in Section 14.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            5.6. Data Retention
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Agreement unless a longer retention period is required or permitted by law.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            5.7. Children's Privacy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our Service is not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently received personal information from a minor, we will take steps to delete such information promptly.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                            5.8. International Data Transfers
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Your information may be transferred to and maintained on servers located outside your country of residence. We take appropriate measures to ensure that your data is treated securely and in accordance with this Agreement.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            6. Disclaimers
                        </Typography>
                        <Typography variant="body1" component="div">
                            <ul>
                                <li>No Medical Advice: The Service is intended for informational purposes only and does not constitute veterinary medical advice, diagnosis, or treatment. Always seek the advice of a qualified veterinarian with any questions you may have regarding your pet's health.</li>
                                <li>Reference-Only Content: All content, recommendations, and information provided by Petalife are for reference purposes only. They are not intended to replace professional veterinary consultations. Before making any changes or decisions regarding your pet's health or care, you should consult with a qualified veterinarian.</li>
                                <li>Accuracy: While we strive to provide accurate and up-to-date information, we do not guarantee the accuracy, completeness, or reliability of the Service's content or classifications.</li>
                                <li>No Warranties: The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied.</li>
                            </ul>
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            7. Limitation of Liability
                        </Typography>
                        <Typography variant="body1" paragraph>
                            To the fullest extent permitted by law, Petalife shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, arising out of or related to your use of the Service.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            8. Indemnification
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You agree to indemnify, defend, and hold harmless Petalife and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Service.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            9. Termination
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We reserve the right to suspend or terminate your access to the Service, without prior notice or liability, for any reason, including if you breach this Agreement. Upon termination, your right to use the Service will immediately cease.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            10. Governing Law
                        </Typography>
                        <Typography variant="body1" paragraph>
                            This Agreement shall be governed and construed in accordance with the laws of Hong Kong, without regard to its conflict of law provisions.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            11. Dispute Resolution
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Any disputes arising out of or related to this Agreement or the Service shall be resolved through binding arbitration in Hong Kong, except that either party may seek injunctive or equitable relief in a court of competent jurisdiction.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            12. Changes to This Agreement
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We may update our Agreement from time to time. We will notify you of any changes by posting the new Agreement on this page. Your continued use of the Service after any changes constitutes acceptance of the new Agreement.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            13. Contact Us
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you have any questions about this Agreement, please contact us:
                            Email: eugene@petalife.tech/oscar@petalife.tech
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <Button onClick={handleClose} color="primary" style={{ margin: isMobile ? '8px 16px 16px' : '16px 24px 24px' }}>
                    Close
                </Button>
            </Dialog>
            {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions> */}
        </div>
    );
}

export default App;
