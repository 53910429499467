import React, { createContext, useContext, useState, useEffect } from 'react';

const PetContext = createContext();

export function PetProvider({ children }) {
    const [userPetInfo, setUserPetInfo] = useState(() => {
        const stored = localStorage.getItem('userPetInfo');
        return stored ? JSON.parse(stored) : null;
    });

    const [petsData, setPetsData] = useState(() => {
        const stored = localStorage.getItem('petsData');
        return stored ? JSON.parse(stored) : [];
    });

    const [currentLang, setCurrentLang] = useState(() => {
        return localStorage.getItem('language') || '繁';
    });

    useEffect(() => {
        if (userPetInfo) {
            localStorage.setItem('userPetInfo', JSON.stringify(userPetInfo));
        }
    }, [userPetInfo]);

    useEffect(() => {
        if (petsData.length > 0) {
            localStorage.setItem('petsData', JSON.stringify(petsData));
        }
    }, [petsData]);

    useEffect(() => {
        localStorage.setItem('language', currentLang);
    }, [currentLang]);

    return (
        <PetContext.Provider value={{ 
            userPetInfo, 
            setUserPetInfo, 
            petsData, 
            setPetsData,
            currentLang,
            setCurrentLang 
        }}>
            {children}
        </PetContext.Provider>
    );
}

export function usePetContext() {
    return useContext(PetContext);
} 