import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  IconButton,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { usePetContext } from '../context/PetContext';

function LandingPage({ toggleLanguage, language }) {
  const { setUserPetInfo, setPetsData } = usePetContext();
  // Get the user's attributes
  const [userAttributes, setUserAttributes] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedPet, setSelectedPet] = useState('');
  const [searchBreed, setSearchBreed] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [petName, setPetName] = useState('');
  const [petGender, setPetGender] = useState('');
  const [petBirthday, setPetBirthday] = useState({
    day: '',
    month: '',
    year: ''
  });
  const [petPhoto, setPetPhoto] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [petWeight, setPetWeight] = useState('');
  const [selectedFoodBrand, setSelectedFoodBrand] = useState('');
  // const [userPetInfo, setUserPetInfo] = useState(null);

  useEffect(() => {
    const fetchUserPetInfo = async () => {
      try {
        const attributes = await fetchUserAttributes();
        const userId = attributes.sub;
        
        const response = await fetch('https://vjdpdy1eqi.execute-api.us-east-2.amazonaws.com/UAT', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId
          })
        });
        console.log('response', response);
        if (!response.ok) {
          throw new Error('Failed to fetch user pet information');
        }

        const data = await response.json();
        
        // Parse the pets data from the body property
        const petsData = JSON.parse(data.body);
        console.log('petsData', petsData);
        // Sort the data by date (oldest first)
        let sortedPetsData = null;
        if (petsData && petsData.length > 0) {
          sortedPetsData = petsData.sort((a, b) => {
            const dateA = new Date(a.Time);
            const dateB = new Date(b.Time);
            return dateA - dateB;
          });
          setUserPetInfo(sortedPetsData[0]);
          setPetsData(sortedPetsData);
        }

        

        // Navigate based on whether user has pets or not
        if (sortedPetsData && sortedPetsData.length > 0) {
          navigate('/home');
        } else {
          navigate('createpet');
        }
      } catch (error) {
        console.error('Error fetching user pet information:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserPetInfo();
  }, [setUserPetInfo, setPetsData]);

  return (
    <Box 
      sx={{ 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#FFA726' // Matches your app's theme color
      }}
    >
      <CircularProgress 
        sx={{ 
          color: 'white',
          mb: 2
        }} 
      />
      <Typography 
        sx={{ 
          color: 'white',
          fontWeight: 500
        }}
      >
        Loading...
      </Typography>
    </Box>
  );
}

export default LandingPage;