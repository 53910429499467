import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslations } from '../../translations';
import { usePetContext } from '../../context/PetContext';
import yellow_orange from '../../assets/stool-color/yellow_orange.png';
import Chocolate_brown from '../../assets/stool-color/Chocolate brown.png';
import green from '../../assets/stool-color/green.png';
import grey from '../../assets/stool-color/grey.png';
import black from '../../assets/stool-color/black.png';
import red_streaks from '../../assets/stool-color/red streak.png';
import white_spots from '../../assets/stool-color/white spot.png';

function StoolReportContent({ report, image, timestamp, satisfaction }) {
    const { currentLang } = usePetContext();
    const t = useTranslations(currentLang);

    const parseReport = (reportData) => {
        if (!reportData) return null;
        try {
            const typeMatch = reportData.type.match(/Type (\d+):/);
            const typeDescMatch = reportData.type.match(/Type \d+: (.*?)(?:\(|$)/);

            return {
                type: typeMatch ? typeMatch[1] : '',
                typeDescription: typeDescMatch ? typeDescMatch[1].trim() : '',
                shape: reportData.shape || '',
                shapeDescription: reportData.shape || '',
                texture: reportData.texture || '',
                textureDescription: reportData.texture || '',
                color: reportData.color || '',
                colorDescription: reportData.color || '',
                analysisResult: reportData.analysis || '',
                additionalQuestionAnswer: reportData.additionalQuestion || ''
            };
        } catch (error) {
            console.error('Error parsing report:', error);
            return null;
        }
    };

    const getColorIcon = (color) => {
        const colorMap = {
            [t.stoolAnalysisReport.orangeYellow]: yellow_orange,
            [t.stoolAnalysisReport.chocolateBrown]: Chocolate_brown,
            [t.stoolAnalysisReport.green]: green,
            [t.stoolAnalysisReport.grey]: grey,
            [t.stoolAnalysisReport.black]: black,
            [t.stoolAnalysisReport.redStreaks]: red_streaks,
            [t.stoolAnalysisReport.whiteSpots]: white_spots
        };

        return colorMap[color] || Chocolate_brown;
    };

    const getSatisfactionEmoji = (satisfaction) => {
        const emojiMap = {
            '1': '😢',
            '2': '🙁',
            '3': '😐',
            '4': '😊',
            '5': '😆'
        };

        return emojiMap[satisfaction] || '😐';
    };

    const parsedReport = parseReport(report);

    return (
        <>
            {/* Image and Upload Info */}
            <Paper elevation={0} sx={{ borderRadius: '12px', overflow: 'hidden', mb: 2 }}>
                <Box sx={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', bgcolor: '#f5f5f5' }}>
                    <img 
                        src={image} 
                        alt={t.stoolAnalysisReport.sampleImage}
                        style={{ 
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '100%',
                            maxHeight: '300px',
                            objectFit: 'contain',
                            margin: '0 auto'
                        }} 
                    />
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                        {t.stoolAnalysisReport.uploadedOn}: {timestamp}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {t.stoolAnalysisReport.ownerComment}: {getSatisfactionEmoji(satisfaction)}
                    </Typography>
                </Box>
            </Paper>

            {/* Analysis Content */}
            {parsedReport && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {/* Type Section */}
                    <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                        <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                            🎯 {t.stoolAnalysisReport.type}
                        </Typography>
                        <Typography variant="h6" sx={{ color: '#2E7D32', fontWeight: 'bold', mb: 1 }}>
                            {t.stoolAnalysisReport.typePrefix} {parsedReport.type}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {parsedReport.typeDescription}
                        </Typography>
                    </Paper>

                    {/* Shape Section */}
                    <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                        <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                            ⭕ {t.stoolAnalysisReport.shape}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {parsedReport.shape}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {parsedReport.shapeDescription}
                        </Typography>
                    </Paper>

                    {/* Texture Section */}
                    <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                        <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                            🗒️ {t.stoolAnalysisReport.texture}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {parsedReport.texture}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {parsedReport.textureDescription}
                        </Typography>
                    </Paper>

                    {/* Color Section */}
                    <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                        <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                            🎨 {t.stoolAnalysisReport.color}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                            <Box 
                                component="img"
                                src={getColorIcon(parsedReport.color)}
                                sx={{ width: 24, height: 24, objectFit: 'cover' }} 
                            />
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {parsedReport.color}
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                            {parsedReport.colorDescription}
                        </Typography>
                    </Paper>

                    {/* Analysis Result Section */}
                    <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            {t.stoolAnalysisReport.analysisResult}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                            {parsedReport.analysisResult}
                        </Typography>
                    </Paper>
                </Box>
            )}
        </>
    );
}

export default StoolReportContent; 