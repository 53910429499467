import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from '@mui/material';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PetsIcon from '@mui/icons-material/Pets';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import pawPrint from '../assets/paw-print.png';
import infinityPaw from '../assets/infinity-paw.png';
import scorebox from '../assets/scorebox.png';
import stoolIcon from '../assets/stool-icon.png';
import jointIcon from '../assets/joint-icon.png';
import walkingIcon from '../assets/walking-icon.png';
import aichatImg from '../assets/aichat-img.png';
import petImage from '../assets/pet-image.png';
import { useNavigate } from 'react-router-dom';
import petalifeHealthScore from '../assets/petalife-health-score.png';
import tokenIcon from '../assets/token.png';
import { usePetContext } from '../context/PetContext';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import defaultPetImage from '../assets/paw-print.png'; // Make sure to add a default image to your assets
import { Authenticator } from '@aws-amplify/ui-react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslations } from '../translations';

function HomePage() {
    const { userPetInfo, petsData, setUserPetInfo, currentLang, setCurrentLang } = usePetContext();
    const t = useTranslations(currentLang);
    console.log('userPetInfo', userPetInfo);
    console.log('petsData', petsData);
    const navigate = useNavigate();
    const petImageUrl = userPetInfo?.File_name
        ? `https://petalife-pet.s3.us-east-2.amazonaws.com/${userPetInfo.File_name}`
        : defaultPetImage; // You might want to add a default image URL here

    const today = new Date();
    const formattedDate = `📅 ${today.getDate()}, ${today.toLocaleDateString('en-US', {
        month: 'short'
    })} ${today.getFullYear()}`;

    // Add click handler
    const handleScoreClick = () => {
        navigate('/score-panel');
    };

    const handleAddPet = () => {
        navigate('/createpet');
    };

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handlePetSelect = (selectedPet) => {
        setUserPetInfo(selectedPet);  // Update the selected pet in global context
        handleCloseDialog();
    };

    const handleStoolAnalysisClick = () => {
        navigate('/stool-analysis');
    };

    const handleSignOut = async (signOut) => {
        try {
            // Clear localStorage
            localStorage.removeItem('userPetInfo');
            localStorage.removeItem('petsData');
            
            // Sign out from Amplify
            await signOut();
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    // Add language change handler
    const handleLangChange = () => {
        const newLang = currentLang === 'EN' ? '繁' : 'EN';
        setCurrentLang(newLang);
    };

    return (
        <Authenticator>
            {({ signOut }) => (
                <Box sx={{ mx: 'auto', bgcolor: '#F5F5F5' }}>
                    <Button
                        onClick={handleLangChange}
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            color: 'white',
                            fontSize: '0.875rem',
                            minWidth: 'auto',
                            padding: '4px 8px',
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <Typography sx={{ fontSize: '0.875rem' }}>
                            {currentLang} | {currentLang === 'EN' ? '繁' : 'EN'}
                        </Typography>
                    </Button>

                    {/* Header Section - Updated with curved bottom design */}
                    <Box
                        sx={{
                            p: 3,
                            pb: 4,
                            mb: 2,
                            bgcolor: '#0A2472', // Dark blue color
                            color: 'white',
                            borderRadius: '0 0 32px 32px',
                        }}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 120,
                            right: 16,
                            zIndex: 1000
                        }}>
                            <Tooltip title="Logout">
                                <IconButton
                                    onClick={() => handleSignOut(signOut)}
                                    sx={{
                                        color: '#1A237E',
                                        bgcolor: 'white',
                                        boxShadow: 1,
                                        '&:hover': {
                                            bgcolor: '#f5f5f5'
                                        }
                                    }}
                                >
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {/* Date */}
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 3,
                                fontSize: '1.5rem',
                                fontWeight: 'bold'
                            }}
                        >
                            {formattedDate}
                        </Typography>

                        {/* Profile Section */}
                        <Box sx={{ display: 'flex', gap: 3, alignItems: 'flex-start' }}>
                            {/* Left side - Avatar and controls */}
                            <Box sx={{ position: 'relative' }}>
                                <Box
                                    component="img"
                                    src={petImageUrl}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        borderRadius: '24px',
                                        mb: 1
                                    }}
                                />
                                <Box sx={{
                                    display: 'flex',
                                    gap: 1,
                                    mt: 1
                                }}>
                                    <IconButton
                                        onClick={handleAddPet}
                                        sx={{
                                            bgcolor: 'white',
                                            '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
                                            width: 36,
                                            height: 36
                                        }}
                                    >
                                        <Box component="span" sx={{ fontSize: '1.2rem' }}>+</Box>
                                    </IconButton>
                                    <IconButton
                                        onClick={handleOpenDialog}
                                        sx={{
                                            bgcolor: 'white',
                                            '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
                                            width: 36,
                                            height: 36
                                        }}
                                    >
                                        <ChangeCircleIcon sx={{ fontSize: '3rem', color: '#0A2472' }} />
                                    </IconButton>
                                </Box>
                            </Box>

                            {/* Right side - Info */}
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mb: 2,
                                        fontSize: '2rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {t.home.hello} {userPetInfo?.petName}! 👋
                                </Typography>

                                {/* Stats */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Box
                                            component="img"
                                            src={petalifeHealthScore}
                                            alt="Petalife Health Score"
                                            sx={{
                                                width: 24,  // Adjust size as needed
                                                height: 24
                                            }}
                                        />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>92%</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Box
                                            component="img"
                                            src={tokenIcon}
                                            alt="Token"
                                            sx={{
                                                width: 24,  // Adjust size as needed
                                                height: 24
                                            }}
                                        />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>1,700 {t.home.token}</Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: 'rgba(255,255,255,0.7)',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {t.home.topUp}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Arrow button */}
                            {/* <IconButton 
                        sx={{ 
                            bgcolor: 'white',
                            '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
                            width: 40,
                            height: 40
                        }}
                    >
                        <Box component="span" sx={{ color: '#0A2472' }}>→</Box>
                    </IconButton> */}
                        </Box>
                    </Box>

                    {/* Health Score Section */}
                    <Box sx={{ px: 3, mb: 3 }}>
                        <Typography
                            variant="h5"
                            sx={{
                                mb: 2,
                                fontWeight: 'bold',
                                color: '#1A237E'
                            }}
                        >
                            {t.home.healthScore}
                        </Typography>

                        <Paper
                            elevation={2}
                            // onClick={handleScoreClick}
                            sx={{
                                p: 3,
                                borderRadius: 4,
                                bgcolor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                                cursor: 'pointer',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.02)'
                                }
                            }}
                        >
                            {/* Score Box */}
                            <Box sx={{ position: 'relative' }}>
                                <Box
                                    component="img"
                                    src={scorebox}
                                    sx={{
                                        width: 140,
                                        height: 140,
                                        objectFit: 'contain',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                />
                                <Typography
                                    variant="h2"
                                    sx={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        lineHeight: 1,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',  // Center the text
                                        zIndex: 1  // Ensure text appears above the image
                                    }}
                                >
                                    {/* 92 */}
                                    Null
                                </Typography>
                            </Box>

                            {/* Score Description */}
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#1A237E',
                                        fontWeight: 'bold',
                                        mb: 1
                                    }}
                                >
                                    {t.home.healthScoreTitle}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#666',
                                        lineHeight: 1.5
                                    }}
                                >
                                    {t.home.healthScoreDesc}
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>

                    {/* Habit Tracker Section */}
                    <Box sx={{ px: 3, mb: 3 }}>
                        <Typography
                            variant="h5"
                            sx={{
                                mb: 2,
                                fontWeight: 'bold',
                                color: '#1A237E'
                            }}
                        >
                            {t.home.habitTracker}
                        </Typography>

                        {/* Stool Analysis */}
                        <Paper
                            elevation={1}
                            onClick={handleStoolAnalysisClick}
                            sx={{
                                p: 2,
                                mb: 2,
                                borderRadius: 4,
                                bgcolor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                '&:hover': {
                                    bgcolor: '#f5f5f5',
                                    transition: 'background-color 0.3s'
                                }
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                <Box sx={{
                                    bgcolor: '#F5F7FA',
                                    p: 3,
                                    borderRadius: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Box
                                        component="img"
                                        src={stoolIcon}
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: '#0A2472'
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#1A237E',
                                            mb: 0.5
                                        }}
                                    >
                                        {t.home.stoolAnalysis}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#666',
                                            maxWidth: '280px'
                                        }}
                                    >
                                        {t.home.stoolDesc}
                                    </Typography>
                                </Box>
                            </Box>
                            {/* <Box 
                        sx={{ 
                            border: '2px solid #0A2472',
                            borderRadius: 2,
                            width: 24,
                            height: 24,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#0A2472'
                        }}
                    >
                        ✓
                    </Box> */}
                        </Paper>

                        {/* Joint Analysis */}
                        <Paper
                            elevation={1}
                            sx={{
                                p: 2,
                                mb: 2,
                                borderRadius: 4,
                                bgcolor: '#F5F5F5',  // Changed to light grey
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                opacity: 0.6,  // Added opacity
                                cursor: 'not-allowed'  // Added not-allowed cursor
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                <Box sx={{
                                    bgcolor: '#E0E0E0',  // Darker grey for icon background
                                    p: 3,
                                    borderRadius: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Box
                                        component="img"
                                        src={jointIcon}
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: '#9E9E9E',  // Grey color for icon
                                            filter: 'grayscale(100%)'  // Make icon greyscale
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#9E9E9E',  // Grey color for text
                                            mb: 0.5
                                        }}
                                    >
                                        {t.home.jointAnalysis}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#9E9E9E',  // Grey color for text
                                            maxWidth: '280px'
                                        }}
                                    >
                                        {t.home.underDev}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                        {/* Step Tracking */}
                        <Paper
                            elevation={1}
                            onClick={() => navigate('/step-tracking')}
                            sx={{
                                p: 2,
                                mb: 2,
                                borderRadius: 4,
                                bgcolor: '#F5F5F5',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                opacity: 1,
                                cursor: 'pointer',
                                '&:hover': {
                                    bgcolor: '#EEEEEE',
                                    transition: 'background-color 0.3s'
                                }
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                <Box sx={{
                                    bgcolor: '#E0E0E0',
                                    p: 3,
                                    borderRadius: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Box
                                        component="img"
                                        src={walkingIcon}
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            color: '#9E9E9E',
                                            filter: 'grayscale(100%)'
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#9E9E9E',
                                            mb: 0.5
                                        }}
                                    >
                                        {t.home.stepTracking}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#9E9E9E',
                                            maxWidth: '280px'
                                        }}
                                    >
                                        {t.home.underDev}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    {/* AI Veterinarian Section */}
                    <Box sx={{ px: 3 }}>
                        <Typography
                            variant="h5"
                            sx={{
                                mb: 2,
                                fontWeight: 'bold',
                                color: '#1A237E'
                            }}
                        >
                            {t.home.aiVet}
                        </Typography>

                        <Paper
                            onClick={() => navigate('/aichatbox')}
                            elevation={2}
                            sx={{
                                p: 3,
                                borderRadius: 4,
                                bgcolor: '#0A2472',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box onClick={() => navigate('/aichatbox')}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{t.home.startChat}</Typography>
                                {/* <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 1 }}>2,172 chats</Typography> */}
                                {/* <Typography variant="body2" sx={{ mb: 2 }}>
                            To know more the common health topic!
                        </Typography> */}
                                <Box
                                    onClick={() => navigate('/aichatbox')}
                                    sx={{
                                        bgcolor: 'white',
                                        color: '#0A2472',
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '1.5rem',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                            transition: 'transform 0.2s'
                                        }
                                    }}
                                >
                                    +
                                </Box>
                            </Box>
                            {/* <Box
                        component="img"
                        src={aichatImg}
                        sx={{ width: 120, height: 'auto' }}
                    /> */}
                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: '8rem',  // Made larger for better visibility
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mr: 1  // Add margin to the right to move it left
                                }}
                            >
                                🤖
                            </Typography>
                        </Paper>
                    </Box>

                    {/* Bottom Navigation */}
                    {/* <Paper
                        elevation={3}
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            justifyContent: 'space-around',
                            py: 1,
                            bgcolor: 'white'
                        }}
                    >
                        <IconButton><PetsIcon /></IconButton>
                        <IconButton><DirectionsWalkIcon /></IconButton>
                        <IconButton><ChatIcon /></IconButton>
                        <IconButton><SettingsIcon /></IconButton>
                    </Paper> */}

                    {/* Popup Dialog */}
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        PaperProps={{
                            sx: {
                                borderRadius: 4,
                                maxWidth: '90%',
                                width: '400px'
                            }
                        }}
                    >
                        <Box sx={{ p: 2 }}>
                            <Typography
                                align="center"
                                sx={{
                                    fontWeight: 500,
                                    mb: 2
                                }}
                            >
                                {t.home.selectPet}
                            </Typography>

                            {petsData.map((pet) => (
                                <Box
                                    key={pet.ID}
                                    onClick={() => handlePetSelect(pet)}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        p: 2,
                                        mb: 1,
                                        bgcolor: 'white',
                                        borderRadius: 3,
                                        cursor: 'pointer',
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                        '&:hover': {
                                            bgcolor: '#f8f9fa'
                                        }
                                    }}
                                >
                                    {/* Pet Image */}
                                    <Box
                                        component="img"
                                        src={pet.File_name
                                            ? `https://petalife-pet.s3.us-east-2.amazonaws.com/${pet.File_name}`
                                            : defaultPetImage
                                        }
                                        alt={pet.petName}
                                        sx={{
                                            width: 60,
                                            height: 60,
                                            borderRadius: 3,
                                            objectFit: 'cover',
                                            mr: 2
                                        }}
                                    />

                                    {/* Pet Info */}
                                    <Box sx={{ flex: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Typography sx={{ fontWeight: 500 }}>
                                                {pet.petName}
                                            </Typography>
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    bgcolor: pet.gender === 'F' ? '#FF69B4' : '#4169E1',
                                                    width: 20,
                                                    height: 20,
                                                    '&:hover': {
                                                        bgcolor: pet.gender === 'F' ? '#FF69B4' : '#4169E1',
                                                    }
                                                }}
                                            >
                                                {pet.gender === 'F' ? (
                                                    <FemaleIcon sx={{ color: 'white', fontSize: 14 }} />
                                                ) : (
                                                    <MaleIcon sx={{ color: 'white', fontSize: 14 }} />
                                                )}
                                            </IconButton>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: '#666',
                                                mt: 0.5
                                            }}
                                        >
                                            {pet.breed}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Dialog>
                </Box>
            )}
        </Authenticator>
    );
}

export default HomePage; 