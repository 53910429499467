import React from 'react';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import stoolIcon from '../assets/stool-icon.png';
import { useTranslations } from '../translations';
import { usePetContext } from '../context/PetContext';
import StoolReportContent from './shared/StoolReportContent';

function StoolAnalysisReport() {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentLang } = usePetContext();
    const t = useTranslations(currentLang);
    const { report, image, timestamp, satisfaction } = location.state || {};

    return (
        <Box sx={{ bgcolor: '#f8f9fa', minHeight: '100vh' }}>
            {/* Header */}
            <Paper elevation={0} sx={{ 
                p: 2, 
                mb: 2, 
                borderRadius: '12px 12px 0 0',
                bgcolor: '#FFA726',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton onClick={() => navigate('/stool-analysis')} sx={{ color: 'white', p: 0.5 }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box component="img" src={stoolIcon} sx={{ width: 24, height: 24, filter: 'brightness(0) invert(1)' }} />
                        <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                            {t.stoolAnalysisReport.patentedTool}
                        </Typography>
                    </Box>
                </Box>
            </Paper>

            <StoolReportContent 
                report={report}
                image={image}
                timestamp={timestamp}
                satisfaction={satisfaction}
            />
        </Box>
    );
}

export default StoolAnalysisReport; 