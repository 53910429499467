import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const bounce = keyframes`
  0%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-5px); }
`;

function TypingAnimation() {
    return (
        <Box sx={{ display: 'flex', gap: 1, padding: '8px 12px' }}>
            {[0, 1, 2].map((index) => (
                <Box
                    key={index}
                    sx={{
                        width: '6px',
                        height: '6px',
                        backgroundColor: '#1e3a8a',
                        borderRadius: '50%',
                        animation: `${bounce} 1.4s infinite ease-in-out`,
                        animationDelay: `${index * 0.16}s`,
                    }}
                />
            ))}
        </Box>
    );
}

export default TypingAnimation; 