import React from 'react';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import jointIcon from '../assets/joint-icon.png';
import walkIcon from '../assets/walking-icon.png';
import stoolIcon from '../assets/stool-icon.png';


function ScorePanel() {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/home');
    };

    return (
        <Box sx={{ maxWidth: 600, mx: 'auto', p: 3, bgcolor: '#F5F5F5', minHeight: '100vh' }}>
            {/* Overall Score */}
            <Box 
                sx={{ 
                    mb: 2, 
                    p: 4, 
                    borderRadius: '30px',
                    bgcolor: '#0A2472', 
                    color: 'white',
                    position: 'relative'
                }}
            >
                {/* Back Arrow Button */}
                <IconButton 
                    onClick={handleBackClick}
                    sx={{ 
                        position: 'absolute',
                        top: 16,
                        left: 16,
                        bgcolor: 'white',
                        '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
                        width: 40,
                        height: 40,
                    }}
                >
                    <ArrowBackIcon sx={{ color: '#0A2472' }} />
                </IconButton>

                <Box sx={{ mt: 4, display: 'flex', gap: 3, mb: 3 }}>
                    <Box
                        component="img"
                        src="https://petalife-pet.s3.us-east-2.amazonaws.com/1c9ad150-e839-4902-ab61-8ba8aa83d93e.png"
                        alt="Pet"
                        sx={{ 
                            width: 100,
                            height: 100,
                            borderRadius: '20px'
                        }}
                    />
                    <Box>
                        <Typography variant="h5" sx={{ mb: 1 }}>Overall Score</Typography>
                        <Typography 
                            variant="h1" 
                            sx={{ 
                                fontWeight: 'bold',
                                borderBottom: '2px solid white',
                                display: 'inline-block'
                            }}
                        >
                            92
                        </Typography>
                    </Box>
                </Box>
                
                <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
                    You have healthy lifestyle!
                </Typography>
            </Box>

            {/* Stool Score */}
            <Paper 
                sx={{ 
                    mb: 2, 
                    p: 3, 
                    borderRadius: '16px', 
                    bgcolor: '#FFE0B2',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box component="img" src={stoolIcon} sx={{ width: 32, height: 32 }} />
                    <Typography 
                        sx={{ 
                            fontFamily: "'Outfit', sans-serif",
                            fontSize: '1.2rem',
                            fontWeight: 500,
                            color: '#FF9800',
                            letterSpacing: '0.02em',
                            opacity: 0.9
                        }}
                    >
                        Stool Score
                    </Typography>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FF9800' }}>82</Typography>
            </Paper>

            {/* Joint Score */}
            <Paper 
                sx={{ 
                    mb: 2, 
                    p: 3, 
                    borderRadius: '16px', 
                    bgcolor: '#E3F2FD',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box component="img" src={jointIcon} sx={{ width: 32, height: 32 }} />
                    <Typography 
                        sx={{ 
                            fontFamily: "'Outfit', sans-serif",
                            fontSize: '1.2rem',
                            fontWeight: 500,
                            color: '#1976D2',
                            letterSpacing: '0.02em',
                            opacity: 0.9
                        }}
                    >
                        Joint Score
                    </Typography>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976D2' }}>72</Typography>
            </Paper>

            {/* Habit Score */}
            <Paper 
                sx={{ 
                    mb: 2, 
                    p: 3, 
                    borderRadius: '16px', 
                    bgcolor: '#F5F7FA',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box component="img" src={walkIcon} sx={{ width: 32, height: 32 }} />
                    <Typography 
                        sx={{ 
                            fontFamily: "'Outfit', sans-serif",
                            fontSize: '1.2rem',
                            fontWeight: 500,
                            color: '#424242',
                            letterSpacing: '0.02em',
                            opacity: 0.9
                        }}
                    >
                        Habit Score
                    </Typography>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#424242' }}>82</Typography>
            </Paper>
        </Box>
    );
}

export default ScorePanel; 