import StoolAnalysisRequest from "../components/StoolAnalysisRequest";

export const translations = {
    EN: {
        // HomePage translations
        home: {
            hello: "Hello~",
            level: "level",
            token: "token",
            topUp: "Top-up token",
            healthScore: "Health Score",
            healthScoreTitle: "Petalife Health Score",
            healthScoreDesc: "Base on your activities and your data, we think your help score is pretty high!",
            habitTracker: "Habit Tracker",
            stoolAnalysis: "Stool Analysis",
            stoolDesc: "Take a stool snap and do analysis with our AI!",
            jointAnalysis: "Joint Analysis",
            stepTracking: "Step Tracking",
            underDev: "under development",
            aiVet: "AI Veterinary Assistant",
            startChat: "Start a Chat with our AI veterinary!"
        },

        // CreatePet translations
        createPet: {
            title: "Create Pet Profile",
            petName: "Pet Name",
            enterPetName: "Enter your pet's name",
            breed: "Breed",
            selectBreed: "Select breed",
            gender: "Gender",
            male: "Male",
            female: "Female",
            birthday: "Birthday",
            selectBirthday: "Select birthday",
            weight: "Weight",
            enterWeight: "Enter weight (kg)",
            next: "Next",
            back: "Back",
            done: "Done",
            tellAboutPetType: "Tell me about your pet type",
            tellAboutPetBreed: "Tell me about your pet breed",
            tellAboutPetInfo: "Tell me about your pet",
            dog: "Dog",
            cat: "Cat",
            rabbit: "Rabbit",
            bird: "Bird",
            fish: "Fish",
            reptile: "Reptile",
            continue: "Continue",
            searchBreeds: "Search breeds",
            petPhoto: "Pet Photo (Optional)",
            uploadPhoto: "Upload your photo here",
            submit: "Submit",
            submitLoading: "Submitting...",
            error: "Something went wrong"
        },

        // StoolAnalysis translations
        stoolAnalysis: {
            title: "Stool Analysis",
            uploadPhoto: "Upload Photo",
            takePhoto: "Take Photo",
            analyzing: "Analyzing...",
            uploadInstruction: "Please upload a clear photo of your pet's stool",
            retake: "Retake",
            confirm: "Confirm",
            stoolScore: "Stool Score",
            healthyPoop: "Healthy Poop!",
            record: "Record",
            yourPastRecord: "Your past record",
            knowMore: "know more",
            loadingRecords: "Loading records...",
            noRecords: "No records found",
            today: "Today",
            yesterday: "Yesterday",
            type: "Type"
        },
        stoolAnalysisRequest: {
            patentedTool: "Patented Stool Analysis Tool",
            uploadHere: "Upload your photo here",
            satisfactionQuestion: "Do you feel satisfying of this stool?",
            veryUnsatisfied: "Very Unsatisfied",
            unsatisfied: "Unsatisfied",
            neutral: "Neutral",
            satisfied: "Satisfied",
            verySatisfied: "Very Satisfied",
            additionalQuestion: "Any additional question you want to ask?",
            questionPlaceholder: "Fill in the question",
            requestAnalysis: "Request analysis",
        },
        stoolAnalysisReport: {
            patentedTool: "Patented Stool Analysis Tool",
            sampleImage: "Stool Sample",
            uploadedOn: "Uploaded on",
            ownerComment: "Owner comment",
            type: "Type",
            typePrefix: "Type",
            shape: "Shape",
            texture: "Texture",
            color: "Color",
            analysisResult: "Analysis Result",
            orangeYellow: "Orange/yellow",
            chocolateBrown: "Chocolate brown",
            green: "Green",
            grey: "Grey",
            black: "Black",
            redStreaks: "Red streaks",
            whiteSpots: "White Spots"
        },
        // AIChat translations
        aiChat: {
            title: "AI Veterinary Assistant",
            welcomeMessage: "Hello, I am an AI veterinary assistant developed by the Petalife team. My job is to assist in the early diagnosis of pets, so that problems can be detected and treated early. You can upload a photo of your pet's stool to check its health status, or ask me any pet health-related questions to get immediate advice.",
            chatPlaceholder: "Ask me anything about your pet's health...",
            thinking: "Thinking...",
        },

        // Common translations
        common: {
            loading: "Loading...",
            error: "Something went wrong",
            retry: "Retry",
            cancel: "Cancel",
            save: "Save",
            delete: "Delete",
            edit: "Edit"
        },

        stepTracking: {
            title: "Step Tracking",
            dailySteps: "Daily Steps",
            stepsToday: "Steps Today",
            dailyGoal: "Daily Goal",
            steps: "steps",
            weeklyOverview: "Weekly Overview",
            comingSoon: "Coming Soon",
            distanceWalked: "Distance Walked",
            kilometers: "kilometers",
            locationError: "Unable to access location",
            gpsData: "GPS Data",
            latitude: "Latitude",
            longitude: "Longitude",
            accuracy: "Accuracy",
            lastUpdate: "Last Update",
            distanceTracking: "Distance Tracked",
            meters: "meters",
            totalMeters: "total meters",
            lastDistance: "meters in last movement",
            approximately: "approximately"
        }
    },
    繁: {
        // HomePage translations
        home: {
            hello: "你好~",
            level: "等級",
            token: "代幣",
            topUp: "充值代幣",
            healthScore: "健康分數",
            healthScoreTitle: "寵物生活健康分數",
            healthScoreDesc: "根據您的活動和數據，我們認為您的寵物健康狀況良好！",
            habitTracker: "習慣追蹤",
            stoolAnalysis: "糞便分析",
            stoolDesc: "拍攝糞便照片並使用AI進行分析！",
            jointAnalysis: "關節分析",
            stepTracking: "步數追蹤",
            underDev: "開發中",
            aiVet: "AI獸醫助手",
            startChat: "開始與AI獸醫對話！"
        },

        // CreatePet translations
        createPet: {
            title: "創建寵物檔案",
            petName: "寵物名稱",
            enterPetName: "輸入寵物名稱",
            breed: "品種",
            selectBreed: "選擇品種",
            gender: "性別",
            male: "男",
            female: "女",
            birthday: "生日",
            selectBirthday: "選擇生日",
            weight: "體重",
            enterWeight: "輸入體重（公斤）",
            next: "下一步",
            back: "返回",
            done: "完成",
            tellAboutPetType: "告訴我你的寵物類型",
            tellAboutPetBreed: "告訴我你的寵物品種",
            tellAboutPetInfo: "告訴我你的寵物資訊",
            dog: "狗",
            cat: "貓",
            rabbit: "兔子",
            bird: "鳥",
            fish: "魚",
            reptile: "爬蟲",
            continue: "繼續",
            searchBreeds: "搜尋品種",
            petPhoto: "寵物照片(選填)",
            uploadPhoto: "上傳你的照片",
            submit: "提交",
            submitLoading: "提交中...",
            error: "發生錯誤"
        },

        // StoolAnalysis translations
        stoolAnalysis: {
            title: "糞便分析",
            uploadPhoto: "上傳照片",
            takePhoto: "拍照",
            analyzing: "分析中...",
            uploadInstruction: "請上傳清晰的寵物糞便照片",
            retake: "重拍",
            confirm: "確認",
            stoolScore: "糞便評分",
            healthyPoop: "健康的糞便！",
            record: "記錄",
            yourPastRecord: "過往記錄",
            knowMore: "了解更多",
            loadingRecords: "載入記錄中...",
            noRecords: "沒有找到記錄",
            today: "今天",
            yesterday: "昨天",
            type: "類型"
        },
        stoolAnalysisRequest: {
            patentedTool: "專利糞便分析工具",
            uploadHere: "在此上傳照片",
            satisfactionQuestion: "您對這次的糞便感到滿意嗎？",
            veryUnsatisfied: "非常不滿意",
            unsatisfied: "不滿意",
            neutral: "普通",
            satisfied: "滿意",
            verySatisfied: "非常滿意",
            additionalQuestion: "有任何其他問題想詢問嗎？",
            questionPlaceholder: "填寫問題",
            requestAnalysis: "請求分析"
        },
        stoolAnalysisReport: {
            patentedTool: "專利糞便分析工具",
            sampleImage: "糞便樣本",
            uploadedOn: "上傳於",
            ownerComment: "主人評論",
            type: "類型",
            typePrefix: "類型",
            shape: "形狀",
            texture: "質地",
            color: "顏色",
            analysisResult: "分析結果",
            orangeYellow: "橙黃色",
            chocolateBrown: "巧克力棕色",
            green: "綠色",
            grey: "灰色",
            black: "黑色",
            redStreaks: "紅色條紋",
            whiteSpots: "白色斑點"
        },
        // AIChat translations
        aiChat: {
            title: "AI 獸醫助手",
            welcomeMessage: "你好，我是由Petalife團隊研發的人工智慧獸醫助理。我的工作是協助寵物預先診斷，以便及早發現和治療。你可上傳寵物糞便的照片以檢查其健康狀況或問我任何寵物健康相關的問題，以獲取即時的建議。",
            chatPlaceholder: "詢問任何關於寵物健康的問題...",
            thinking: "思考中..."
        },

        // Common translations
        common: {
            loading: "載入中...",
            error: "發生錯誤",
            retry: "重試",
            cancel: "取消",
            save: "儲存",
            delete: "刪除",
            edit: "編輯"
        },

        stepTracking: {
            title: "步數追蹤",
            dailySteps: "每日步數",
            stepsToday: "今日步數",
            dailyGoal: "每日目標",
            steps: "步",
            weeklyOverview: "每週概覽",
            comingSoon: "即將推出",
            distanceWalked: "行走距離",
            kilometers: "公里",
            locationError: "無法獲取位置",
            gpsData: "GPS 數據",
            latitude: "緯度",
            longitude: "經度",
            accuracy: "精確度",
            lastUpdate: "最後更新",
            distanceTracking: "距離追蹤",
            meters: "米",
            totalMeters: "總米數",
            lastDistance: "上次移動距離",
            approximately: "約"
        }
    }
};

export const useTranslations = (lang) => {
    return translations[lang] || translations['EN'];
};