import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  IconButton,
  MenuItem,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { useTranslations } from '../translations';
import { usePetContext } from '../context/PetContext';

function CreatePet() {
  const { currentLang } = usePetContext();
  const t = useTranslations(currentLang);
  // Get the user's attributes
  const [userAttributes, setUserAttributes] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedPet, setSelectedPet] = useState('');
  const [searchBreed, setSearchBreed] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [petName, setPetName] = useState('');
  const [petGender, setPetGender] = useState('');
  const [petBirthday, setPetBirthday] = useState({
    day: '',
    month: '',
    year: ''
  });
  const [petPhoto, setPetPhoto] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [petWeight, setPetWeight] = useState('');
  const [selectedFoodBrand, setSelectedFoodBrand] = useState('');
  const [selectedPetType, setSelectedPetType] = useState('');

  useEffect(() => {
    // Move the user attributes fetch to the main effect function
    const getUserAttributes = async () => {
      try {
        const attributes = await fetchUserAttributes();
        setUserAttributes(attributes);
        console.log(attributes);
        console.log(attributes.sub);
        console.log(attributes.email);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };

    getUserAttributes();

    // Keep the cleanup function for petPhoto only
    return () => {
      if (petPhoto) {
        URL.revokeObjectURL(petPhoto);
      }
    };
  }, [petPhoto]);

  const renderPetTypeStep = () => (
    <Box sx={{ p: 2 }}>
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: 4,
          color: '#1A2027',
          fontSize: '2rem'
        }}
      >
        {t.createPet.tellAboutPetType}
      </Typography>

      {/* Pet Type Options */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {[
          { value: 'dog', icon: '🐕', label: t.createPet.dog },
          { value: 'cat', icon: '🐱', label: t.createPet.cat },
          { value: 'rabbit', icon: '🐰', label: t.createPet.rabbit },
          { value: 'bird', icon: '🐦', label: t.createPet.bird },
          { value: 'fish', icon: '🐠', label: t.createPet.fish },
          { value: 'reptile', icon: '🦎', label: t.createPet.reptile }
        ].map((pet) => (
          <Button
            key={pet.value}
            onClick={() => {
              setSelectedPet(pet.value);
              setSearchBreed('');
              setSelectedPetType(pet.value);
            }}
            sx={{
              p: 3,
              borderRadius: '16px',
              bgcolor: selectedPet === pet.value ? '#FFA726' : '#FFF',
              color: selectedPet === pet.value ? '#FFF' : '#1A2027',
              border: '1px solid',
              borderColor: selectedPet === pet.value ? '#FFA726' : '#E0E0E0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: selectedPet === pet.value ? '#FFA726' : '#F8F9FA',
                boxShadow: 'none'
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography fontSize="1.5rem">{pet.icon}</Typography>
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 500
                }}
              >
                {pet.label}
              </Typography>
            </Box>
            <Box
              sx={{
                width: 24,
                height: 24,
                border: '2px solid',
                borderColor: selectedPet === pet.value ? '#FFF' : '#E0E0E0',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {selectedPet === pet.value && (
                <Box sx={{
                  width: 12,
                  height: 12,
                  bgcolor: '#FFF',
                  borderRadius: '2px'
                }} />
              )}
            </Box>
          </Button>
        ))}
      </Box>

      {/* Continue Button */}
      <Button
        fullWidth
        onClick={() => selectedPet && setStep(2)}
        disabled={!selectedPet}
        sx={{
          mt: 4,
          bgcolor: '#FFA726',
          color: '#FFF',
          p: 2,
          borderRadius: '16px',
          textTransform: 'none',
          fontSize: '1.1rem',
          '&:hover': {
            bgcolor: '#FF9800'
          },
          '&.Mui-disabled': {
            bgcolor: '#FFE0B2',
            color: '#FFF'
          }
        }}
        endIcon={<ArrowForwardIcon />}
      >
        {t.createPet.continue}
      </Button>
    </Box>
  );

  const renderBreedStep = () => {
    const breeds = {
      cat: [
        'American Shorthair', 'British Shorthair', 'Scottish Fold', 'Manx', 
        'Russian Blue', 'Bengal', 'Egyptian Mau', 'Japanese Bobtail', 
        'Turkish Shorthair', 'Burmilla', 'Persian', 'Himalayan', 
        'Norwegian Forest Cat', 'Turkish Angora', 'Siberian', 
        'Maine Coon', 'Ragdoll', 'Somali', 'Birman', 'Canadian Sphynx', 
        'Peterbald', 'Donskoy', 'Devon Rex', 'Exotic Shorthair', 
        'American Curl', 'Munchkin', 'Balinese', 'Bombay', 'Selkirk Rex', 
        'Oriental Shorthair', 'Abyssinian', 'Korat', 'Chartreux', 
        'Singapura', 'Ocicat', 'Highland Fold', 'Toyger', 'Snowshoe', 
        'Oriental Longhair', 'Sokoke', 'Arabian Mau', 'Tibetan Cat', 
        'Polar Cat', 'Ukrainian Levkoy', 'Miniature Leopard Cat', 
        'Serengeti', 'Chinese Domestic Cat', 'Others'
      ],
      dog: [
        'Chihuahua', 'Pomeranian', 'Yorkshire Terrier', 'Maltese', 'Bichon Frise',
        'Pekingese', 'Shih Tzu', 'Pug', 'Japanese Chin', 'Toy Poodle', 'Dachshund',
        'French Bulldog', 'Miniature Schnauzer', 'Welsh Corgi', 'Scottish Terrier',
        'Basset Hound', 'Cairn Terrier', 'West Highland White Terrier', 'Shiba Inu',
        'Border Collie', 'Beagle', 'Chow Chow', 'Siberian Husky', 'English Cocker Spaniel',
        'Shar Pei', 'Australian Shepherd', 'American Staffordshire Terrier', 'German Shepherd',
        'Labrador Retriever', 'Golden Retriever', 'Doberman', 'Alaskan Malamute', 'Akita Inu',
        'Great Dane', 'Rottweiler', 'Saint Bernard', 'Tibetan Mastiff', 'Lowchen', 'Papillon',
        'Brussels Griffon', 'Smooth Fox Terrier', 'Havanese', 'Parson Russell Terrier',
        'Czech Terrier', 'Boston Terrier', 'Newfoundland', 'Vizsla',
        'German Shorthaired Pointer', 'Bernese Mountain Dog', 'Samoyed',
        'Nova Scotia Duck Tolling Retriever', 'Irish Wolfhound', 'Dogue de Bordeaux',
        'Dalmatian', 'Weimaraner', 'Kuvasz', 'American Toy Terrier', 'Russian Toy Terrier',
        'Brittany Dog', 'Curly-Coated Belgian Dog', 'Smooth-Coated Brussels Griffon',
        'Italian Greyhound', 'Sealyham Terrier', 'Norwegian Elkhound', 'Dutch Kooikerhondje',
        'Cesky Terrier', 'Spanish Water Dog', 'Portuguese Water Dog', 'Australian Cattle Dog',
        'Anatolian Shepherd', 'Belgian Shepherd', 'Canadian Royal Dog', 'Luxembourg Shepherd', 
        'Great Pyrenees', 'Others'
      ],
      rabbit: ['Holland Lop', 'Netherland Dwarf', 'Mini Rex', 'Dutch', 'Lionhead', 'Others'],
      bird: ['Parakeet', 'Cockatiel', 'Lovebird', 'Finch', 'Canary', 'Others'],
      fish: ['Goldfish', 'Betta', 'Guppy', 'Tetra', 'Angel Fish', 'Others'],
      reptile: ['Bearded Dragon', 'Leopard Gecko', 'Ball Python', 'Corn Snake', 'Green Anole', 'Others']
    };

    const currentBreeds = breeds[selectedPet] || [];
    const filteredBreeds = currentBreeds.filter(breed =>
      breed.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <Box sx={{ p: 2 }}>
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: 4,
            color: '#1A2027',
            fontSize: '2rem'
          }}
        >
          {t.createPet.tellAboutPetBreed}
        </Typography>

        {/* Search Input */}
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t.createPet.searchBreeds}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '16px',
              '& fieldset': {
                borderColor: '#E0E0E0',
              },
              '&:hover fieldset': {
                borderColor: '#FFA726',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FFA726',
              },
            },
          }}
        />

        {/* Breed Options */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxHeight: '50vh',
          overflowY: 'auto',
          mb: 4,
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#FFA726',
            borderRadius: '4px',
            '&:hover': {
              background: '#FF9800',
            },
          },
        }}>
          {filteredBreeds.map((breed) => (
            <Button
              key={breed}
              onClick={() => setSearchBreed(breed)}
              sx={{
                p: 3,
                borderRadius: '16px',
                bgcolor: searchBreed === breed ? '#FFA726' : '#FFF',
                color: searchBreed === breed ? '#FFF' : '#1A2027',
                border: '1px solid',
                borderColor: searchBreed === breed ? '#FFA726' : '#E0E0E0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textTransform: 'none',
                boxShadow: 'none',
                '&:hover': {
                  bgcolor: searchBreed === breed ? '#FFA726' : '#F8F9FA',
                  boxShadow: 'none'
                }
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 500
                }}
              >
                {breed}
              </Typography>
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  border: '2px solid',
                  borderColor: searchBreed === breed ? '#FFF' : '#E0E0E0',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {searchBreed === breed && (
                  <Box sx={{
                    width: 12,
                    height: 12,
                    bgcolor: '#FFF',
                    borderRadius: '2px'
                  }} />
                )}
              </Box>
            </Button>
          ))}
        </Box>

        {/* Continue Button */}
        <Button
          fullWidth
          onClick={() => setStep(3)}
          disabled={!searchBreed}
          sx={{
            mt: 2,
            bgcolor: '#FFA726',
            color: '#FFF',
            p: 2,
            borderRadius: '16px',
            textTransform: 'none',
            fontSize: '1.1rem',
            '&:hover': {
              bgcolor: '#FF9800'
            },
            '&.Mui-disabled': {
              bgcolor: '#FFE0B2',
              color: '#FFF'
            }
          }}
          endIcon={<ArrowForwardIcon />}
        >
          {t.createPet.continue}
        </Button>
      </Box>
    );
  };

  const renderFoodBrandStep = () => (
    <Box sx={{ p: 2 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: 4,
          color: '#1A2027',
          fontSize: '2rem'
        }}
      >
        {t.createPet.tellAboutPetFood}
      </Typography>

      {/* Food Brand Options */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {[
          { value: 'royal-canin', label: 'Royal Canin' },
          { value: 'hills', label: 'Hills' },
          { value: 'now-fresh', label: 'Now Fresh' },
          { value: 'open-farm', label: 'Open Farm' },
          { value: 'blue-buffalo', label: 'Blue Buffalo' }
        ].map((brand) => (
          <Button
            key={brand.value}
            onClick={() => setSelectedFoodBrand(brand.value)}
            sx={{
              p: 3,
              borderRadius: '16px',
              bgcolor: selectedFoodBrand === brand.value ? '#FFA726' : '#FFF',
              color: selectedFoodBrand === brand.value ? '#FFF' : '#1A2027',
              border: '1px solid',
              borderColor: selectedFoodBrand === brand.value ? '#FFA726' : '#E0E0E0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: selectedFoodBrand === brand.value ? '#FFA726' : '#F8F9FA',
                boxShadow: 'none'
              }
            }}
          >
            <Typography sx={{ fontSize: '1.1rem', fontWeight: 500 }}>
              {brand.label}
            </Typography>
            <Box
              sx={{
                width: 24,
                height: 24,
                border: '2px solid',
                borderColor: selectedFoodBrand === brand.value ? '#FFF' : '#E0E0E0',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {selectedFoodBrand === brand.value && (
                <Box sx={{
                  width: 12,
                  height: 12,
                  bgcolor: '#FFF',
                  borderRadius: '2px'
                }} />
              )}
            </Box>
          </Button>
        ))}
      </Box>

      {/* Continue Button */}
      <Button
        fullWidth
        onClick={() => setStep(4)}
        disabled={!selectedFoodBrand}
        sx={{
          mt: 4,
          bgcolor: '#FFA726',
          color: '#FFF',
          p: 2,
          borderRadius: '16px',
          textTransform: 'none',
          fontSize: '1.1rem',
          '&:hover': {
            bgcolor: '#FF9800'
          },
          '&.Mui-disabled': {
            bgcolor: '#FFE0B2',
            color: '#FFF'
          }
        }}
        endIcon={<ArrowForwardIcon />}
      >
        {t.createPet.continue}
      </Button>
    </Box>
  );

  const renderBasicInfoStep = () => (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
        {t.createPet.tellAboutPetInfo}
      </Typography>

      {/* Name Input */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>{t.createPet.petName}</Typography>
        <TextField
          fullWidth
          placeholder={t.createPet.enterPetName}
          value={petName}
          onChange={(e) => setPetName(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              '& fieldset': { borderColor: '#F4A460' },
              '&:hover fieldset': { borderColor: '#E78E3A' },
              '&.Mui-focused fieldset': { borderColor: '#E78E3A' },
            }
          }}
        />
      </Box>

      {/* Photo Upload */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>{t.createPet.petPhoto}</Typography>
        <Box
          sx={{
            border: '2px dashed #F4A460',
            borderRadius: 2,
            p: 3,
            textAlign: 'center',
            cursor: 'pointer',
            position: 'relative',
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'
          }}
          onClick={() => document.getElementById('photo-upload').click()}
        >
          <input
            id="photo-upload"
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setPetPhoto(URL.createObjectURL(file));
              }
            }}
          />
          {petPhoto ? (
            <Box
              component="img"
              src={petPhoto}
              alt="Pet preview"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0
              }}
            />
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <PhotoCameraIcon sx={{ color: '#F4A460', mb: 1 }} />
              <Typography color="textSecondary">
                {t.createPet.uploadPhoto}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Gender Selection */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>{t.createPet.gender}</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            onClick={() => setPetGender('Male')}
            sx={{
              flex: 1,
              p: 2,
              borderRadius: '16px',
              bgcolor: petGender === 'Male' ? '#4169E1' : '#FFF',
              color: petGender === 'Male' ? '#FFF' : '#1A2027',
              border: '1px solid',
              borderColor: petGender === 'Male' ? '#4169E1' : '#E0E0E0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: petGender === 'Male' ? '#4169E1' : '#F8F9FA',
                boxShadow: 'none'
              }
            }}
          >
            <Typography sx={{ fontSize: '1.1rem', fontWeight: 500 }}>
              {t.createPet.male}
            </Typography>
            <Typography sx={{ fontSize: '1.2rem' }}>♂</Typography>
          </Button>
          <Button
            onClick={() => setPetGender('Female')}
            sx={{
              flex: 1,
              p: 2,
              borderRadius: '16px',
              bgcolor: petGender === 'Female' ? '#FF69B4' : '#FFF',
              color: petGender === 'Female' ? '#FFF' : '#1A2027',
              border: '1px solid',
              borderColor: petGender === 'Female' ? '#FF69B4' : '#E0E0E0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                bgcolor: petGender === 'Female' ? '#FF69B4' : '#F8F9FA',
                boxShadow: 'none'
              }
            }}
          >
            <Typography sx={{ fontSize: '1.1rem', fontWeight: 500 }}>
              {t.createPet.female}
            </Typography>
            <Typography sx={{ fontSize: '1.2rem' }}>♀</Typography>
          </Button>
        </Box>
      </Box>

      {/* Birthday Selection */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>{t.createPet.birthday}</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {['Day', 'Month', 'Year'].map((unit) => (
            <TextField
              key={unit}
              select
              label={unit}
              value={petBirthday[unit.toLowerCase()]}
              onChange={(e) => setPetBirthday({
                ...petBirthday,
                [unit.toLowerCase()]: e.target.value
              })}
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#F4A460' },
                  '&:hover fieldset': { borderColor: '#E78E3A' },
                  '&.Mui-focused fieldset': { borderColor: '#E78E3A' },
                }
              }}
            >
              {unit === 'Day' && Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                <MenuItem key={day} value={day}>{day}</MenuItem>
              ))}
              {unit === 'Month' && Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                <MenuItem key={month} value={month}>{month}</MenuItem>
              ))}
              {unit === 'Year' && Array.from({ length: 30 }, (_, i) => new Date().getFullYear() - i).map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </TextField>
          ))}
        </Box>
      </Box>

      {/* Weight Input */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>{t.createPet.weight}</Typography>
        <TextField
          fullWidth
          placeholder='kg'
          value={petWeight}
          type="number"
          inputProps={{
            step: "0.1",  // Allows decimals with one decimal place
            min: "0"      // Prevents negative values
          }}
          onChange={(e) => {
            const value = e.target.value;
            // Only update if it's a valid number
            if (!isNaN(value) && value >= 0) {
              setPetWeight(value);
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              '& fieldset': { borderColor: '#F4A460' },
              '&:hover fieldset': { borderColor: '#E78E3A' },
              '&.Mui-focused fieldset': { borderColor: '#E78E3A' },
            }
          }}
        />
      </Box>

      <Button
        fullWidth
        variant="contained"
        sx={{
          mt: 2,
          bgcolor: '#F4A460',
          '&:hover': { bgcolor: '#E78E3A' }
        }}
        onClick={handleSubmitToAPI}
        disabled={
          isLoading ||
          !petName ||
          !petGender ||
          !petBirthday.day ||
          !petBirthday.month ||
          !petBirthday.year ||
          // !selectedFoodBrand ||
          !petWeight
        }
      >
        {isLoading ? t.createPet.submitLoading : t.createPet.submit}
      </Button>

      {/* Add error message display */}
      {error && (
        <Typography
          color="error"
          sx={{
            mt: 2,
            fontSize: '0.875rem'
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );

  const handleSubmitToAPI = async () => {
    setIsLoading(true);
    setError('');

    try {
      // Convert photo to base64
      let photoBase64 = null;
      if (petPhoto) {
        // Fetch the image from the URL and convert to base64
        const response = await fetch(petPhoto);
        const blob = await response.blob();
        photoBase64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }

      const formData = {
        petName,
        userId: userAttributes.sub,
        userEmail: userAttributes.email,
        petGender,
        petBirthday: {
          day: petBirthday.day,
          month: petBirthday.month,
          year: petBirthday.year
        },
        selectedPet,
        breed: searchBreed,
        foodBrand: selectedFoodBrand,
        weight: petWeight,
        photo: photoBase64 // Send the base64 string instead of the URL
      };

      const response = await fetch('https://f008niojxd.execute-api.us-east-2.amazonaws.com/UAT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit data');
      }

      const data = await response.json();
      console.log('Success:', data);
      navigate('/'); // Navigate to success page

    } catch (error) {
      console.error('Error:', error);
      setError('Failed to submit information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 2 }}>
      {/* Header with Back, Progress, Skip */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        mb: 4,
        p: 2
      }}>
        <Button
          onClick={() => setStep(prev => Math.max(1, prev - 1))}
          sx={{
            minWidth: '40px',
            width: '40px',
            height: '40px',
            p: 1,
            bgcolor: '#F8F9FA',
            borderRadius: '12px',
            color: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: '1.2rem' }} />
        </Button>

        <Box sx={{
          flex: 1,
          height: 4,
          bgcolor: '#F1F1F1',
          borderRadius: 2,
        }}>
          <Box sx={{
            // width: `${(step / (selectedPetType ? (['dog', 'cat'].includes(selectedPetType) ? 4 : 3) : 4)) * 100}%`,
            width: `${(step /4) * 100}%`,
            height: '100%',
            bgcolor: '#FFA726',
            borderRadius: 2
          }} />
        </Box>

        {/* <Button
          onClick={() => navigate('/home')}
          sx={{
            color: '#666',
            fontSize: '1rem',
            textTransform: 'none',
            minWidth: 'fit-content'
          }}
        >
          Skip
        </Button> */}
      </Box>

      {step === 1 && renderPetTypeStep()}
      {step === 2 && renderBreedStep()}
      {step === 3 && renderBasicInfoStep()}
      {/* {(step === 3 && (selectedPetType === 'dog' || selectedPetType === 'cat')) && renderFoodBrandStep()}
      {((step === 3 && !['dog', 'cat'].includes(selectedPetType)) || 
        (step === 4 && ['dog', 'cat'].includes(selectedPetType))) && renderBasicInfoStep()} */}
    </Box>
  );
}

export default CreatePet;